<template>
     <section class="BodySectionInformationSanitizer">
    <div class="ContentCenterInformation">
      <div class="LeftContainerInformation">
         <div class="center1">
  <div class="property-card1">
    <a href="#">
      <div class="property-image1">
        <div class="property-image-title1">
          <!-- Optional <h5>Card Title</h5> If you want it, turn on the CSS also. -->
        </div>
      </div></a>
    <div class="property-description1" >
      <h5> Más Información </h5>
      <p>Limpia y sanitiza en un solo paso,<br> desinfecta profesionalmente en <br>segundos, 
      efectivo contra virus, <br> bacterias, hongos y levaduras; No requiere enjuague, no es corrosivo 
      y facil aplicacion, entre otros beneficios.</p>
    </div>
    
  </div>
</div>
      </div>
       <div class="RightContainerInformation">
           <div class="container1">
  <div class="box1">
    <img src="https://images.pexels.com/photos/3962332/pexels-photo-3962332.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
   
  </div>
  <div class="box1">
    <img src="https://images.pexels.com/photos/3952223/pexels-photo-3952223.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
  
  </div>
  <div class="box1">
    <img src="https://images.pexels.com/photos/4098761/pexels-photo-4098761.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
  
  </div>
  <div class="box1">
    <img src="https://images.pexels.com/photos/3986971/pexels-photo-3986971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
  </div>
</div>
       </div>
    </div>
          </section>

</template>

<script>
export default {
    name: "Information",
}
</script>
<style lang="scss">
.BodySectionInformationSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
   background:linear-gradient(30deg, #329B93, #4BB0A9, #239089, #4BB0A9, #329B93);
}
.ContentCenterInformation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.LeftContainerInformation {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 25%;
  height: 100%;

}
.RightContainerInformation {
  display: flex;
  justify-content: right;
  align-items: center;
  align-self: center;
  width: 75%;
  height: 100%;
  
}
*
{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h5
{
  margin:0px;
  font-size:1.4em;
  font-weight:700;
}

p
{
  font-size:12px;
}

.center1
{
  height:100vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End Non-Essential  */

.property-card1
{
  height:30em;
  width:25em;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  position:relative;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius:16px;
  overflow:hidden;
  

}
/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image1
{
  height:30em;
  width:26em;
  padding:1em 2em;
  position:Absolute;
  top:0px;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-image:url('https://images.pexels.com/photos/4098579/pexels-photo-4098579.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  background-size:cover;
  background-repeat:no-repeat;
  border-radius: -10px;
}

/* Bottom Card Section */

.property-description1
{
  background-color: #ffffff;
  font-family: "Righteous", cursive;
  height:20em;
  width:26em;
  position:absolute;
  bottom:0em;
  -webkit-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align:center;
}


.property-card1:hover .property-description1
{
  height:0em;
  padding:0px 1em;
}
.property-card1:hover .property-image1
{
    border-radius: -10px;
  height:30em;
}
.container1 {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  height: 100vh;
}

.box1 {
  flex: 1;
  overflow: hidden;
  transition: .5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0,0,0,.1);
  line-height: 0;
}

.box1 > img {
  width: 200%;
  height: calc(100% - 10vh);
  object-fit: cover; 
  transition: .5s;
}

.box1 > span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.box1:hover { flex: 1 1 50%; }
.box1:hover > img {
  width: 100%;
  height: 100%;
}
 @media (max-width: 1200px){
    .BodySectionInformationSanitizer{
        height: 210vh;

    }
    .ContentCenterInformation{
      
        flex-direction: column;
        justify-content: space-around;
    }
    .RightContainerInformation{
        width: 100%;
        height: 50%;
    }
    .LeftContainerInformation{

        width: 100%;
        height: 50%;
    }
}
</style>
