<template>
     <section class="BodySectionReserveSanitizer">
    <div class="ContentCenterReserve">
      <div class="LeftContainerReserve">
           <div class="DescriptionReserve">
          <h1 align="center" style="color:#168A87">
            <strong> ¡AGENDA TU CITA! </strong>
            <br />
           1. Aproxima la fecha que requieres <br />
      
        2. Verifica que la tengamos disponible <br />
        3. Agenda tu cita<br />
        4. Sigue los pasos que marca la pagina <br /><br />
          </h1>
        </div>
      </div>
      <div class="RightContainerReserve">
          <vc-date-picker
   @change="(event)=>{ alert('test');}"
  :value="null"
  color="teal"
  is-dark
 display: none
 v-model='date'
  :min-date='new Date()'
  :attributes='attributes' 
 
  />
           
      </div>
    </div>
     </section>
</template>


<script>
export default {
    name: "Reserve",
    data() {
    return {
      date: new Date(),
    };
  },
     watch: {
        date() {
          location.href="https://spsco.com.mx/help-desk/upload/index.php" ;
        }
      }
}
</script>

<style lang="scss">
.BodySectionReserveSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.ContentCenterReserve {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}
.LeftContainerReserve {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 60%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.RightContainerReserve {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 40%;
  height: 100%;
}
.DescriptionReserve {
  font-family: "Righteous", cursive;
  font-size: 30px;
}
@media (max-width: 1200px){
    .BodySectionReserveSanitizer{
        height: 130vh;

    }
    .ContentCenterReserve{
      
        flex-direction: column;
        justify-content: space-around;
    }
    .RightContainerReserve{
        width: 100%;
        height: 50%;
    }
    .LeftContainerReserve{

        width: 100%;
        height: 50%;
    }
}

</style>