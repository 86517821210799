<template>
  <section class="BodySectionHelpSanitizer">
    <div class="ContentCenterHelp">
      <div class="GridContainerHelp">
        <!-- flip-card-container -->
        <div class="flip-card-container" style="--hue: 220">
          <div class="flip-card">
            <div class="card-front">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/3962332/pexels-photo-3962332.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Brohm Lake"
                />
                <figcaption>Accion Microbina</figcaption>
              </figure>

              <ul>
                <li>
                  El HQ-HDSC atribuye su acción biocida a la inactivación de las
                  enzimas productoras de energía, desnaturalización de las
                  proteínas celulares esenciales y la ruptura de la membrana
                  celular. Por lo que es particularmente efectivo para eliminar
                  bacterias, virus, hongos y algas.
                </li>
              </ul>
            </div>

            <div class="card-back">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/3962332/pexels-photo-3962332.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Brohm Lake"
                />
              </figure>

              <button>Bloquear Tarjeta</button>

              <div class="design-container">
                <span class="design design--1"></span>
                <span class="design design--2"></span>
                <span class="design design--3"></span>
                <span class="design design--4"></span>
                <span class="design design--5"></span>
                <span class="design design--6"></span>
                <span class="design design--7"></span>
                <span class="design design--8"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- /flip-card-container -->

        <!-- flip-card-container -->
        <div class="flip-card-container" style="--hue: 170">
          <div class="flip-card">
            <div class="card-front">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://us.123rf.com/450wm/lesichkadesign/lesichkadesign1511/lesichkadesign151100232/48591581-papel-crep%C3%A9-de-color-turquesa.jpg?ver=6"
                  alt="Image 2"
                />
                <figcaption>Propiedades</figcaption>
              </figure>

              <ul>
                <li>
                  APARIENCIA: Liquido Incoloro a ligeramente amarillo OLOR:
                  Caracterís co PH: 6 a 7.5 PRESENTACIONES: galón, porrón de 20
                  litros y Tambor de 200 litros
                </li>
              </ul>
            </div>

            <div class="card-back">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/4098777/pexels-photo-4098777.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="image-2"
                />
              </figure>

              <button>Bloquear Tarjeta</button>

              <div class="design-container">
                <span class="design design--1"></span>
                <span class="design design--2"></span>
                <span class="design design--3"></span>
                <span class="design design--4"></span>
                <span class="design design--5"></span>
                <span class="design design--6"></span>
                <span class="design design--7"></span>
                <span class="design design--8"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- /flip-card-container -->

        <!-- flip-card-container -->
        <div class="flip-card-container" style="--hue: 350">
          <div class="flip-card">
            <div class="card-front">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/4098577/pexels-photo-4098577.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  alt="Brohm Lake"
                />
                <figcaption>Usos</figcaption>
              </figure>

              <ul>
                <li>
                  EL HQͲHDSC es efectivo para la desinfección y sanitización de
                  ambiente, superficies y objetos sin importar su naturaleza, ya
                  que se puede utilizar en una amplia gama de materiale como
                  madera, vidrio, plastico y telas.
                </li>
              </ul>
            </div>

            <div class="card-back">
              <!-- only if the image is necessary -->
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/4098577/pexels-photo-4098577.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  alt="Brohm Lake"
                />
              </figure>

              <button>Bloquear Tarjeta</button>

              <!-- can add svg here and remove these eight spans -->
              <div class="design-container">
                <span class="design design--1"></span>
                <span class="design design--2"></span>
                <span class="design design--3"></span>
                <span class="design design--4"></span>
                <span class="design design--5"></span>
                <span class="design design--6"></span>
                <span class="design design--7"></span>
                <span class="design design--8"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="flip-card-container" style="--hue: 350">
          <div class="flip-card">
            <div class="card-front">
              <figure>
                <div class="img-bg"></div>
                <img
                  src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgICAgICAgICAgHCAoHBwcHCA8ICQcKFREWFhURExMYHCggGBoxGx8TITEhJSkrLi4uFx8zODMsNygtLisBCgoKDQ0NGg0NDisZFRkrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAQsAvQMBIgACEQEDEQH/xAAWAAEBAQAAAAAAAAAAAAAAAAAAAQX/xAAZEAEAAgMAAAAAAAAAAAAAAAAAARFRgdH/xAAWAQEBAQAAAAAAAAAAAAAAAAAABgX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDCAbSJAAAJAAAAAIAAAAAAAAAAAAAAAAAAAAACwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEgAAAAAAAAAAAAAAAAAAAAAAAAAWAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHDAAAAICggKIoAAAAAAAi5AAB//Z"
                  alt="Brohm Lake"
                />
                <figcaption>Componentes</figcaption>
              </figure>

              <ul>
                <li>
                  (Administración de alimentos y Drogas FDA por sus siglas en
                  Ingles) volumen 3 parte 178 Subparte B (sanitizantes) punto
                  1010 párrafo 17, autoriza el uso de este producto para
                  sanitizar equipos para procesar alimentos, utensilios y
                  superficies en áreas publicas donde se sirven alimentos.
                </li>
              </ul>
            </div>

            <div class="card-back">
              <!-- only if the image is necessary -->
              <figure>
                <div class="img-bg"></div>
                <img
                  src="https://images.pexels.com/photos/4098761/pexels-photo-4098761.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Brohm Lake"
                />
              </figure>

              <button>Bloquear Tarjeta</button>

              <!-- can add svg here and remove these eight spans -->
              <div class="design-container">
                <span class="design design--1"></span>
                <span class="design design--2"></span>
                <span class="design design--3"></span>
                <span class="design design--4"></span>
                <span class="design design--5"></span>
                <span class="design design--6"></span>
                <span class="design design--7"></span>
                <span class="design design--8"></span>
              </div>
            </div>
          </div>
        </div>

        <!-- /flip-card-container -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Help",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.BodySectionHelpSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  
}
.ContentCenterHelp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}
.GridContainerHelp {
  display: grid;
  align-items: center;
  justify-content: center;
  align-self: center;
  grid-template-columns: auto auto auto auto;
  gap: 0px 0px;
  width: 70%;
  height: 90%;
}
/* 
    ================================
        Best Viewed In Full Page
    ================================
*/

/* Hover over a card to flip, can tab too. */

@import url("https://fonts.googleapis.com/css?family=Lato");

/* default */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body */

/* .flip-card-container */
.flip-card-container {
  --hue: 150;
  --primary: hsl(var(--hue), 50%, 50%);
  --white-1: hsl(0, 0%, 90%);
  --white-2: hsl(0, 0%, 80%);
  --dark: hsl(var(--hue), 25%, 10%);
  --grey: hsl(0, 0%, 50%);

  width: 310px;
  height: 500px;
  margin: 15px;

  perspective: 1000px;
}

/* .flip-card */
.flip-card {
  width: inherit;
  height: inherit;

  position: relative;
  transform-style: preserve-3d;
  transition: 0.6s 0.1s;
}

/* hover and focus-within states */
.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
  transform: rotateY(180deg);
}

/* .card-... */
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  border-radius: 24px;

  background: var(--dark);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card-front */
.card-front {
  transform: rotateY(0deg);
  z-index: 2;
}

/* .card-back */
.card-back {
  transform: rotateY(180deg);
  z-index: 1;
}

/* figure */
figure {
  z-index: -1;
}

/* figure, .img-bg */
figure,
.img-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

/* img */
img {
  height: 100%;
  border-radius: 24px;
}

/* figcaption */
figcaption {
  display: block;

  width: auto;
  margin-top: 12%;
  padding: 8px 22px;

  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 2px;
  word-spacing: 6px;
  text-align: right;

  position: absolute;
  top: 0;
  right: 12px;

  color: var(--white-1);
  background: hsla(var(--hue), 25%, 10%, 0.5);
}

/* .img-bg */
.img-bg {
  background: hsla(var(--hue), 25%, 10%, 0.5);
}

.card-front .img-bg {
  clip-path: polygon(0 20%, 100% 40%, 100% 100%, 0 100%);
}

.card-front .img-bg::before {
  content: "";

  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(18deg);

  width: 100%;
  height: 6px;
  border: 1px solid var(--red);
  border-left-color: transparent;
  border-right-color: transparent;

  transition: 0.1s;
}

.card-back .img-bg {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
}

/* hover state */
.flip-card-container:hover .card-front .img-bg::before {
  width: 6px;
  border-left-color: var(--primary);
  border-right-color: var(--primary);
}

/* ul */
ul {
  padding-top: 50%;
  margin: 0 auto;
  width: 70%;
  height: 100%;

  list-style: none;
  color: var(--white-1);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* li */
li {
  width: 100%;
  margin-top: 12px;
  padding-bottom: 12px;

  font-size: 14px;
  text-align: center;

  position: relative;
}

li:nth-child(2n) {
  color: var(--white-2);
}

li:not(:last-child)::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;

  background: currentColor;
  opacity: 0.2;
}

/* button */
button {
  font-family: inherit;
  font-weight: bold;
  color: var(--white-1);

  letter-spacing: 2px;

  padding: 9px 20px;
  border: 1px solid var(--grey);
  border-radius: 1000px;
  background: transparent;
  transition: 0.3s;

  cursor: pointer;
}

button:hover,
button:focus {
  color: var(--primary);
  background: hsla(var(--hue), 25%, 10%, 0.2);
  border-color: currentColor;
}

button:active {
  transform: translate(2px);
}

/* .design-container */
.design-container {
  --tr: 90;
  --op: 0.5;

  width: 100%;
  height: 100%;

  background: transparent;
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
}

/* .design */
.design {
  display: block;

  background: var(--grey);
  position: absolute;

  opacity: var(--op);
  transition: 0.3s;
}

.design--1,
.design--2,
.design--3,
.design--4 {
  width: 1px;
  height: 100%;
}

.design--1,
.design--2 {
  top: 0;
  transform: translateY(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--1 {
  left: 20%;
}

.design--2 {
  left: 80%;
}

.design--3,
.design--4 {
  bottom: 0;
  transform: translateY(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%));
}

.design--3 {
  left: 24%;
}

.design--4 {
  left: 76%;
}

.design--5,
.design--6,
.design--7,
.design--8 {
  width: 100%;
  height: 1px;
}

.design--5,
.design--6 {
  left: 0;
  transform: translateX(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--5 {
  top: 41%;
}

.design--6 {
  top: 59%;
}

.design--7,
.design--8 {
  right: 0;
  transform: translateX(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%));
}

.design--7 {
  top: 44%;
}

.design--8 {
  top: 56%;
}

/* states */
button:hover + .design-container,
button:active + .design-container,
button:focus + .design-container {
  --tr: 20;
  --op: 0.7;
}

.abs-site-link {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: hsla(0, 82%, 47%, 0.6);
  font-size: 16px;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
}

@media (max-width: 1199px){
    .BodySectionHelpSanitizer{
        height: 400vh;

    }
    .ContentCenterHelp{
      
        flex-direction: column;
        justify-content: space-around;
    }
    .GridContainerHelp {
  display: grid;
  align-items: center;
  justify-content: center;
  align-self: center;
  grid-template-columns: auto ;
  gap: 0px 0px;
  width: 90%;
  height: 90%;
}
}
@media (min-width: 1200px) and  (max-width: 1280px){
    .BodySectionHelpSanitizer{
        height: 180vh;

    }
    .ContentCenterHelp{
      
        flex-direction: column;
        justify-content: space-around;
    }
    .GridContainerHelp {
  display: grid;
  align-items: center;
  justify-content: center;
  align-self: center;
  grid-template-columns: auto auto;
  gap: 0px 0px;
  width: 90%;
  height: 90%;
}
}

</style>
