<template>
  <div class="Content">
    
     <meta 
     name='viewport' 
     content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
/>
    <img class="Logo" src="../assets/images/logo.png" />
    <div class="wrapper">
      <div class="icon facebook" >
        <div class="tooltip">Facebook</div>
          <a
                        href="https://www.facebook.com/delriosolutions/"
                        target="_blank"
                        ><font-awesome-icon
                            size="1x"
                            :icon="['fab', 'facebook-f']"
                            style="color: white;"
                        ></font-awesome-icon
                        ></a>
      </div>

      <div class="icon whatsapp">
        <div class="tooltip">WhatsApp</div>
         <a href="https://wa.link/4h2am8" target="_blank"
                        ><i
                            ><font-awesome-icon
                            size="1x"
                            :icon="['fab', 'whatsapp']"
                            style="color: white;"
                            ></font-awesome-icon></i
                        ></a>
      </div>

      <div class="icon github">
        <div class="tooltip">DRS</div>
        <a href="http://delriosolutions.com.mx" target="_blank"
                        ><i
                            ><font-awesome-icon
                            size="1x"
                            :icon="['fas', 'globe']"
                             style="color: white;"
                            ></font-awesome-icon></i
                        ></a>
      </div>

      <div class="icon youtube">
        <div class="tooltip">Youtube</div>

        <a href="https://youtu.be/aF_sigxZb-w" target="_blank"
                        ><i
                            ><font-awesome-icon
                            size="1x"
                            :icon="['fab', 'youtube']"
                             style="color: white;"
                            ></font-awesome-icon></i
                        ></a>
      </div>
    </div>
    <div>
      <home></home>
      <help></help>
      <hqthdsc></hqthdsc>
      <pdfs></pdfs>
      <nebbia></nebbia>
      <videos></videos>
      <information></information>
      <reserve></reserve>
    </div>
  </div>
</template>

<script>
import Home from "../components/Home.vue";
import Help from "../components/Help.vue";
import Hqthdsc from "../components/Hqthdsc.vue";
import Pdfs from "../components/Pdfs.vue";
import Nebbia from "../components/Nebbia.vue";
import Videos from "../components/Videos.vue";
import Information from "../components/Information.vue";
import Reserve from "../components/Reserve.vue";
export default {
  name: "Page",
  components: {
    Home,
    Help,
    Hqthdsc,
    Pdfs,
    Nebbia,
    Videos,
    Information,
   Reserve,
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.Logo {
  display: inline-flex;
  position: fixed;
	z-index: 10;
	left: 5%;
  width:7%;
  height:15%;
  margin-top:2%;
  margin-Left:85%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}


.wrapper {
  
  display: inline-flex;
  position: fixed;
	z-index: 10;
	left: 0;
	right: 0;
	top: 86%;
  left:72%;
}

.wrapper .icon {
  position: relative;
  background-color:hsl(213, 10%, 17%);
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(163, 134, 134, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background-color: #3b5999;
  color: #ffffff;
}

.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
  background-color: #4de130;
  color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background-color: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background-color: #de463b;
  color: #ffffff;
}

@media (max-width: 1300px){
    .wrapper{
      margin-left: -45%;
      
    }
}
@media (max-width: 800px){
    .Logo{
      width:10%;
  height:10%;
    }
}
@media (max-width: 485px){
    .Logo{
      width:10%;
  height:9%;
  margin-top:4%;
    }
}
@media (max-width: 360px){
    .Logo{
      width:9%;
  height:7%;
  margin-top:9%;
    }
}


// dressing

</style>
