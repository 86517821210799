<template>
    <section class="BodySectionPdfSanitizer">
        <div class="ContentCenterPdf">
      <ul class="cards2">
  <li>
    <a href="https://drive.google.com/file/d/1s6sP9i19a2WITooAhwU6mUO6Q89JGO32/view?usp=sharing"  target="_blank" class="card2">
      <img src="../assets/images/pdf1.jpg" class="card2__image" alt="" />
      <div class="card2__overlay">
        <div class="card2__header">
          <img class="card2__thumb" src="../assets/images/logo.png"  alt="" />
          <div class="card2__header-text">         
          </div>
        </div>
      </div>
    </a>      
  </li>
  <li>
    <a href="https://drive.google.com/file/d/14XyEGKLxDs21HJn00BfZzE-WYGVDfD-9/view?usp=sharing" target="_blank" class="card2">
      <img src="../assets/images/pdf2.jpg" class="card2__image" alt="" />
      <div class="card2__overlay">        
        <div class="card2__header">
           <img class="card2__thumb" src="../assets/images/logo.png"  alt="" />
          <div class="card2__header-text">
          </div>
        </div>
      </div>
    </a>
  </li>
  <li>
    <a href="https://drive.google.com/file/d/1DsiUlzu9FiCPOjgQdiGW7l6tYcQIP_LO/view?usp=sharing" target="_blank" class="card2">
      <img src="../assets/images/pdf3.jpg" class="card2__image" alt="" />
      <div class="card2__overlay">
        <div class="card2__header">               
          <img class="card2__thumb" src="../assets/images/logo.png"  alt="" />
          <div class="card2__header-text">    
          </div>
        </div>
      </div>
    </a>
  </li>
  <li>
  </li>    
</ul>
      </div>
    </section>
</template>


<script>
export default {
    name: "Pdfs",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.BodySectionPdfSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150vh;
   background:linear-gradient(30deg, #329B93, #4BB0A9, #239089, #4BB0A9, #329B93);
}
.ContentCenterPdf {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
 
}

.cards2 {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card2{
  position: relative;
  display: block;
  height: 100%;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card2__image {      
  width: 100%;
  height: auto;
}

.card2__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 5px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
   background-color: #a6d8d5;
}

.card2:hover .card2__overlay {
  transform: translateY(0);
}

.card2__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card2__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}


.card2:hover .card2__header {
  transform: translateY(0);
}

.card2__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card2__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card2__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card2__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card2__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}   
.Cont{
 margin-top:-65%;
}



</style>