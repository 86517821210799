<template>
  <section class="BodySectionVideosSanitizer">
    <div class="ContentCenterVideos">
      <div class="LeftContainerVideos">
        <div class="DescriptionVideos">
          <h1 align="center" style="color:#168A87">
            <strong> MÁS INFORMACIÓN </strong>
            <br />
            Te dejamos unos videos relacionados con la información anerior,
            esperamos <br />sean de tú agrado.
          </h1>
        </div>
      </div>

      <div class="RightContainerVideos">
        <div id="slider0">
          <input type="radio" name="slider" id="slide10" checked />
          <input type="radio" name="slider" id="slide20" />
          <input type="radio" name="slider" id="slide30" />
          <input type="radio" name="slider" id="slide40" />
          <div id="slides">
            <div id="overflow">
              <div class="inner">
                <div class="slide slide_10">
                  <div class="slide-content"></div>
                  <video width="400" height="200" controls muted>
                    <source
                      src="../assets/Videos/Video1.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="slide slide_20">
                  <div class="slide-content"></div>
                  <video width="400" height="200" controls muted>
                    <source
                      src="../assets/Videos/Video2.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="slide slide_30">
                  <div class="slide-content"></div>
                  <video width="400" height="200" controls muted>
                    <source
                      src="../assets/Videos/Video3.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="slide slide_40">
                  <div class="slide-content"></div>
                  <video width="400" height="200" controls muted>
                    <source
                      src="../assets/Videos/Video4.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <p id="videos"></p>
                </div>
              </div>
            </div>
          </div>
          <div id="controls">
            <label for="slide10"></label>
            <label for="slide20"></label>
            <label for="slide30"></label>
            <label for="slide40"></label>
          </div>
          <div id="bullets">
            <label for="slide10"></label>
            <label for="slide20"></label>
            <label for="slide30"></label>
            <label for="slide40"></label>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Videos",
};
</script>

<style lang="scss">
.BodySectionVideosSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.ContentCenterVideos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}
.LeftContainerVideos {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 50%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.RightContainerVideos {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 50%;
  height: 100%;
}
.DescriptionVideos {
  font-family: "Righteous", cursive;
  font-size: 30px;
}
#slider0 {
  margin-top: -2%;
  margin-left: 14%;
  width: 400px;
  text-align: center;
}
#slider0 input[type="radio"] {
  display: none;
}
#slider0 label {
  cursor: pointer;
  text-decoration: none;
}
#slides {
  padding: 10px;
  border: 3px solid #2ed3aa;
  background: #ffffff;

  z-index: 1;
}
#overflow {
  width: 100%;
  overflow: hidden;
}
#slide10:checked ~ #slides .inner {
  margin-left: 0;
}
#slide20:checked ~ #slides .inner {
  margin-left: -100%;
}
#slide30:checked ~ #slides .inner {
  margin-left: -200%;
}
#slide40:checked ~ #slides .inner {
  margin-left: -300%;
}
#slides .inner {
  transition: margin-left 800ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 400%;
  line-height: 0;
  height: 350px;
}
#slides .slide {
  width: 25%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
}
#slides .slide_10 {
  background: #075c5f;
}
#slides .slide_20 {
  background: #136969;
}
#slides .slide_30 {
  background: #00a1a7;
}
#slides .slide_40 {
  background: #4dbec2;
}
#controls {
  margin: -180px 0 0 0;
  width: 100%;
  height: 50px;
  z-index: 3;
  position: relative;
}
#controls label {
  transition: opacity 0.2s ease-out;
  display: none;
  width: 50px;
  height: 50px;
  opacity: 0.4;
}
#controls label:hover {
  opacity: 1;
}

#bullets {
  margin: 150px 0 0;
  text-align: center;
}
#bullets label {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #2ed3aa;
}
#slide10:checked ~ #bullets label:nth-child(1),
#slide20:checked ~ #bullets label:nth-child(2),
#slide30:checked ~ #bullets label:nth-child(3),
#slide40:checked ~ #bullets label:nth-child(4) {
  background: #054244;
}
@media screen and (max-width: 900px) {
  #slide10:checked ~ #controls label:nth-child(2),
  #slide20:checked ~ #controls label:nth-child(3),
  #slide30:checked ~ #controls label:nth-child(4),
  #slide40:checked ~ #controls label:nth-child(1),
  #slide10:checked ~ #controls label:nth-last-child(2),
  #slide20:checked ~ #controls label:nth-last-child(3),
  #slide30:checked ~ #controls label:nth-last-child(4),
  #slide40:checked ~ #controls label:nth-last-child(1) {
    margin: 0;
  }
  #slides {
    max-width: calc(100% - 140px);
    margin: 0 auto;
  }
}
@media (max-width: 1200px){
    .BodySectionVideosSanitizer{
        height: 120vh;
    }
    .ContentCenterVideos{
      
        flex-direction: column;
        justify-content: space-around;
    }
    .RightContainerVideos{
        width: 150%;
        height: 50%;
       justify-content: right;
        
    }
    .LeftContainerVideos{

        width: 100%;
        height: 50%;
    }
    #slider0{
      margin-right:10%;
      width: 170%;
    }
}

</style>
