<template>
  <section class="BodySectionNebbiaSanitizer">
        <div class="LeftContainerNebbia">
                      <div class="DescriptionNebbia">
          <h1 align="center" style="color:#168A87">
            <strong> ¿Sabias Qué? </strong>
            <br />
          HQ-HDSC es un producto formulado <br>especialmente para la limpieza y 
              desinfeccion <br/>de áreas y superficies,  que requieran una
              desinfeccion efectiva. 
             
          </h1>
        </div>
        </div>
        <div class="RightContainerNebbia">
                      <img class="Image" src="../assets/images/Nebbia.jpg" />
        </div>
  </section>
</template>

<script>
export default {
  name: "Nebbia",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.BodySectionNebbiaSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

}
.LeftContainerNebbia {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 55%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.RightContainerNebbia {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  width: 45%;
  height: 100%;
}
.Image{
  width:50%;
  height:70%;
}
.DescriptionNebbia {
  font-family: "Righteous", cursive;
  font-size: 30px;
}

@media (max-width: 730px){
    .BodySectionNebbiaSanitizer{
        height: 120vh;
        flex-direction: column;
    }
    .ContentCenterNebbia{
        flex-direction: column;
        justify-content: space-around;
    }
    .RightContainerNebbia{
        width: 100%;
        height: 50%;
        flex-direction: column;
    }
    .LeftContainerNebbia{
flex-direction: column;
        width: 90%;
        height: 50%;
    }
}



</style>  