<template>
  <section class="BodySectionHomeSanitizer">
    <div class="ContentCenterHome">
      <div class="LeftContainerHome">
        <div>
          <h1>
            SANITIZANTE DEL RIO SOLUTIONS
          </h1>
          <h3>
            <p>
              El HQ-HDSC es un producto desinfectante de alto rendimiento con
              propiedades bactericidas, fungicidas y viricidas, para la higiene
              de superficiespor contacto o por via aerea (termo nebulizacion).
              La eficacia de sus componentes esta ampliamente probada, y cuentan
              con aprobaciones por agencia internacionales como son la EPA, la
              FDA y la ECHA
            </p>
          </h3>
          <a href="https://spsco.com.mx/help-desk/upload/index.php" target="_blank">
            <button class="ButtonContact">Contactanos</button>
          </a>
        </div>
      </div>
      <div class="RightContainerHome">
        <p class="Mensaje"  @click="CloseMessage" v-if="Message">Da click para mostrar  y despues click a la foto para desglosarla </p>
       <div class="container123">
    <input type="radio" name="slider" id="item0-0" checked>
    <input type="radio" name="slider" id="item0-1">
    <input type="radio" name="slider" id="item0-2">
  <div class="cards123">
    <label class="card123" for="item0-0" id="song-0">
       <img  src="../assets/images/ImgC1.jpeg" />
    </label>
    <label class="card123" for="item0-1" id="song-1">
      <img  src="../assets/images/ImgC2.jpeg" />
    </label>
    <label class="card123"  for="item0-2" id="song-2">
       <img  src="../assets/images/ImgC3.jpeg" />
       
    </label>
  </div>
</div>
      <div class="info-area" id="test">
      </div>
    </div>
    <div class="progress-bar">
      <span class="progress"></span>
    </div>
      </div>
    
  </section>
 <!-- <div class="container">
         <div class="cards" v-for="(card,index) in images.SectionHome" v-bind:key="index">
             <label class="card" :v-for="'item'+(index+1)" :id="'song'+(index+1)">
               {{index}}
              <img :src=" require('@/assets/'+ card)" />
            </label>-->
</template>

<script>
import ImgHome from "../assets/Json/properties.json"
export default {
  name: "Home",
  data(){
  return{
   Message:true
  }
  },
  computed: {
    images() {
      return [
      ImgHome

      ]
    },
  },
  methods:{
    img: function(){
getImgUrl(pet) 
    var images = require.context('../assets/', false, /\.jpg$/)
    return images('./' + pet + ".jpg")
  
    },
    CloseMessage:function(){
      this.Message=false;
    }
  }
  
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.BodySectionHomeSanitizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
background-color: white;
}
.ContentCenterHome {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.LeftContainerHome {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 40%;
  height: 100%;
}
.LeftContainerHome h1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 3.3em;
  color: #2ed3aa;
}
.LeftContainerHome p {
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-weight: bold;
  font-size: 1.3em;
  padding: 50px;
  color: #2ed3aa;
}
.RightContainerHome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  z-index: 0;
}
.ButtonContact {
  width: 35%;
  height: 55px;
  border: none;
  outline: none;
  margin-top: 3%;
  background-color: #2d3436;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
  position: relative;
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');


* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  font-family: 'Righteous', cursive;
  transition: background 0.4s ease-in;
  background-color: #c394f8;

  &.blue {
    background-color: #428aa6;
  }
}

input[type="radio"] {
  display: none;
}

.card123 {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.container123 {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards123 {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

#item0-0:checked ~ .cards123 #song-2,
#item0-1:checked ~ .cards123 #song-0,
#item0-2:checked ~ .cards123 #song-1 {
  transform: translatex(-40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item0-0:checked ~ .cards123 #song-1,
#item0-1:checked ~ .cards123 #song-2,
#item0-2:checked ~ .cards123 #song-0 {
  transform: translatex(40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item0-0:checked ~ .cards123 #song-0,
#item0-1:checked ~ .cards123 #song-1,
#item0-2:checked ~ .cards123 #song-2 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;

  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}

#item0-0:checked ~ .player #test {
  transform: translateY(0);
}

#item0-1:checked ~ .player #test {
  transform: translateY(-40px);
}

#item0-2:checked ~ .player #test {
  transform: translateY(-80px);
}

.Mensaje {
	// layout
  margin-left:60%;
	max-width: 100em;
  height: 50em;
	display: inline-flex;
  position: absolute;
	z-index: 10;
	left: -7.8%;
	right: 0;
	// looks
	background-color: rgba(255, 255, 255, 0.781);
	padding: 24em 5.5em;
	font-size: 1.25em;
	border-radius: 1rem;
 
}

.Mensaje::before {
	// layout
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 0.5em; // offset should move with padding of parent
	border: .75rem solid transparent;
	border-top: none;


	// looks
	
}


@media (max-width: 1200px){
    .BodySectionHomeSanitizer{
        height: 200vh;

    }
    .ContentCenterHome{
        flex-direction: column;
        justify-content: space-around;
    }
    .RightContainerHome{
        width: 100%;
        height: 50%;
    }
    .LeftContainerHome{

        width: 100%;
        height: 50%;
    }
    .Mensaje{
      margin-right:10%;

  height: 60em;
  }
}


</style>
