import Vue from 'vue'
import VueRouter from 'vue-router'
import Page from '../components/Page.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Page',
        component: Page
    },
    {
        path: '/home',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Home.vue')
    },
    {
        path: '/help',
        name: 'Help',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Help.vue')
    },
    {
        path: '/hqthdsc',
        name: 'Hqthdsc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Hqthdsc.vue')
    },
    {
        path: '/pdfs',
        name: 'Pdfs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Pdfs.vue')
    },
 {
        path: '/nebbia',
        name: 'Nebbia',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Nebbia.vue')
    },
    {
        path: '/videos',
        name: 'Videos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Videos.vue')
    },
    {
        path: '/information',
        name: 'Information',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Information.vue')
    },
    {
        path: '/reserve',
        name: 'Reserve',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/Reserve.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router